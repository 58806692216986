.ContactPageBanner {
    background: url(https://edian.io/assets/banner_connect.jpg);
    background-repeat: no-repeat;
    background-size: auto;    
    height:400px;
    margin-left:auto;
    margin-right:auto;
    max-width:1024px;
    width:100%;
    margin-bottom:100px;
}

.ContactPageBanner p {
    position: relative;
    background: red;
    padding: 15px;
    color: #444;
    top: 300px;
    margin: 15px;
    background: #eee;
    opacity: .8;
}

.PageBlockSuccess {
    border:1px solid green;
    background:#efe;
    padding:20px
}

.PageBlockError {
    border:1px solid red;
    background:#fee;
    padding:20px
}