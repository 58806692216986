.CreatePage {

}

.CreatePageBanner {
    background: url(https://edian.io/assets/banner_create.jpg);
    background-repeat: no-repeat;
    background-size: auto;    
    height:400px;
    margin-left:auto;
    margin-right:auto;
    max-width:1024px;
    width:100%;
}

.CreatePageBanner p {
    position: relative;
    background: red;
    padding: 15px;
    color: #444;
    top: 300px;
    margin: 15px;
    background: #eee;
    opacity: .8;
}