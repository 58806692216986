.IdeatePage {

}

.IdeatePageBanner {
    background: url(https://edian.io/assets/banner_ideate.jpg);
    background-repeat: no-repeat;
    background-size: auto;    
    height:400px;
    margin-left:auto;
    margin-right:auto;
    max-width:1024px;
    width:100%;
    margin-bottom:100px;
}

.IdeatePageBanner p {
    position: relative;
    padding: 15px;
    color: #444;
    top: 300px;
    margin: 15px;
    background: #eee;
    opacity: .8;
}