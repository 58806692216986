.App {
    text-align: center;
}

.PrimaryButton {
    border: 1px solid #339dd2;
    display: inline-block;

    max-width: 100px;
    margin: 20px;
    padding: 15px;
    border-radius: 15px;
    cursor: pointer;
    text-align: center;
    min-width: 100px;
    text-decoration: none;
    color: #222;
    width: 100%;
}

.PrimaryButton:hover {
    background-image: linear-gradient(to bottom right, #ffffff, #e9f8ff);
}

.App h1 {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 32px;
    color: #444;
}

.App p {
    padding: 15px;
    color: #444;
}

.App .Page {}

.App .PageHeader {
    position: fixed;
    border-radius: 15px;
    background: #eee;
    top: 130px;
    left: 80px;
    right: 80px;
    opacity: .8;
    color: #000;
    font-size: 32px;
    padding: 20px;
    text-align: center;
}

.App .PageBlock {
    border-bottom: 1px solid #888;
    padding-bottom: 80px;
    margin-bottom: 80px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1024px;
    text-align: center;
}

.App .PageBlock img {
    max-width: 120px;
}

.App .PageBlock .Screenshot {
    width: 100%;
    min-width: 200px !important;
    max-width: 800px !important;
}