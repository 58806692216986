.LandingPageBanner {
    background-repeat: no-repeat;
    background-size: auto;
    background-position: right top;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1920px;
    width: 100%;
    margin-bottom: 120px;
}

.LandingPageBanner img {
    max-width: 256px;
}

.LandingPageBanner p {
    position: relative;
    background: red;
    padding: 15px;
    color: #444;
    top: 300px;
    margin: 15px;
    width: 190px;
    background: #eee;
    opacity: .9;
    margin-left: auto;
    margin-right: auto;
    border-radius: 100%;
}

.LandingPageBanner p img {
    width: 150px;
}

.LandingPage .LandingPageMethod {
    border: 1px solid #339dd2;
    display: inline-block;
    width: 30%;
    max-width: 300px;
    margin: 20px;
    padding: 15px;
    border-radius: 15px;
    cursor: pointer;
    text-align: center;
    min-width: 250px;
}

.LandingPage .LandingPageMethod:hover {
    background: url(https://edian.io/assets/banner_ideate.jpg);
    background-repeat: no-repeat;
    background-size: auto;
    transition: background 0.2s linear;
    color: #fff !important
}

.LandingPage .LandingPageMethod:hover h1 {
    color: #fff
}

.LandingPage .LandingPageMethod:hover p {
    color: #fff
}

.LandingPage .LandingPagePillar {
    max-width: 200px;
    display: inline-block;
    margin: 20px;
}

.LandingPage .LandingPagePillar {
    color: #000;
    width: 250px;
    margin: 20px;
}

.LandingPage .LandingPagePillar svg {
    background-image: linear-gradient(to bottom right, #d8e5ed, #54656c);
    border-radius: 100%;
    padding: 20px;
    color: #2c495c;
}

.LandingPage .EdianFooter {
    margin-top: 100px;
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
    margin-left: auto;
    margin-right: auto;
    padding: 40px 120px;
    text-align: center;
}